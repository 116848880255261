export const rules = {
  /* name: [
    { required: true, message: 'Please input Activity name', trigger: 'blur' },
    { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
  ], */
  gname: [{ required: true, message: '请输入店铺名称', trigger: 'blur' }],
  contacts: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
  tel: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
  mainProducts: [
    { required: true, message: '请输入主营业务', trigger: 'blur' }
  ],
  longitude: [{ required: true, message: '请输入经度', trigger: 'blur' }],
  latitude: [{ required: true, message: '请输入纬度', trigger: 'blur' }]
}
