<template>
  <el-dialog
    :title="title"
    :model-value="modelValue"
    width="1200px"
    draggable
    destroy-on-close
    :z-index="2010"
    @close="closed"
  >
    <div class="news-create-container">
      <el-form
        ref="ruleFormRef"
        style="flex: 2"
        :model="form"
        :rules="formRules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="店铺名称" prop="gname" required>
              <el-input
                v-model.trim="form.gname"
                placeholder="店铺名称"
                @change="gnameChange"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="助记码">
              <el-input
                v-model="form.zjm"
                readonly
                placeholder="助记码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contacts" required>
              <el-input
                v-model.trim="form.contacts"
                placeholder="联系人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="tel" required>
              <el-input
                v-model.trim="form.tel"
                placeholder="联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="联系地址">
          <el-input
            v-model.trim="form.address"
            :rows="2"
            type="textarea"
            placeholder="联系地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="主营业务" prop="mainProducts" required>
          <el-input
            v-model.trim="form.mainProducts"
            :rows="2"
            type="textarea"
            placeholder="主营业务"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="商店类型" prop="tel" required>
          <el-input
            v-model.trim="form.tel"
            placeholder="商店类型"
          ></el-input>
        </el-form-item> -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="坐标（经度）" prop="longitude" required>
              <el-input
                v-model.trim="form.longitude"
                placeholder="经度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="坐标（纬度）" prop="latitude" required>
              <el-input
                v-model.trim="form.latitude"
                placeholder="纬度"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="开户行">
              <el-input
                v-model.trim="form.jcdwb"
                placeholder="请填写开户行"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <el-input
                v-model.trim="form.jcdw"
                placeholder="请填写开户行账号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <!-- <el-form-item label="提供发票类型" prop="tel" required>
          <el-input
            v-model.trim="form.tel"
            placeholder="提供发票类型"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="含税" prop="tel" required>
          <el-input
            v-model.trim="form.tel"
            placeholder="含税"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="结算方式" prop="tel" required>
          <el-input
            v-model.trim="form.tel"
            placeholder="结算方式"
          ></el-input>
        </el-form-item> -->

        <el-row>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input
                v-model="form.remarks"
                :rows="3"
                type="textarea"
                placeholder="备注"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item label="上架">
          <el-radio-group v-model="form.homeShow">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>

      <div class="photo-container">
        <el-form :model="form" label-width="80px" label-position="top">
          <el-form-item label="图片">
            <UploadImage
              v-model="form.storeImage"
              width="200px"
              height="200px"
              format="array"
            ></UploadImage>
            <!-- <el-input v-model="form.storeImage" placeholder="图片"></el-input> -->
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed, onMounted } from 'vue'
import * as storeApi from '@/api/store.js'
import { ElMessage } from 'element-plus'
import { pinyin } from 'pinyin-pro'
import UploadImage from '@/components/UploadImage/index.vue'
import { rules } from '../data.js'

const props = defineProps({
  modelValue: {
    type: Boolean,
    require: true
  },
  info: {
    type: Object
  }
})

const emits = defineEmits(['update:modelValue', 'updateClient'])

// 判断是否有参数传递过来
// const isInfoFlag = computed(() => {
//   return JSON.stringify(props.info) === '{}'
// })

// 标题
const title = computed(() => {
  return JSON.stringify(props.info) === '{}' ? '创建' : '修改'
})

// 数据源
const form = ref({})
// 取消
const closed = () => {
  form.value = {}
  emits('update:modelValue', false)
}

watch(
  () => form.value.storeImage,
  val => {
    form.value.imagesrc = val ? val[0].img : ''
    form.value.yasuoImagesrc = val ? val[0].ysImg : ''
  }
)

// 提交事件
const submitLoading = ref(false)
const ruleFormRef = ref(null)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (!valid) {
      console.log('error submit!', fields, Object.keys(fields)[0])
      ElMessage.error(fields[Object.keys(fields)[0]][0].message)
      return
    }

    submitLoading.value = true
    // console.log('form.value :>> ', form.value)
    try {
      form.value.id
        ? await storeApi.updateStore(form.value)
        : await storeApi.addStore(form.value)
      ElMessage.success('更新成功')
      emits('updateClient')
      closed()
    } finally {
      submitLoading.value = false
    }
  })
}

// 获取数据方法
const loading = ref(false)
const getDetail = async () => {
  loading.value = true
  try {
    form.value = JSON.parse(JSON.stringify(props.info))
    if (props.info.imagesrc) {
      form.value.storeImage = [
        { img: form.value.imagesrc, ysImg: form.value.yasuoImagesrc }
      ]
    }
    if (props.info.geoJson?.coordinates?.length > 0) {
      form.value.longitude = props.info.geoJson.coordinates[0]
      form.value.latitude = props.info.geoJson.coordinates[1]
    }
  } catch (error) {
    console.log('error :>> ', error)
  } finally {
    loading.value = false
  }
}
watch(
  [() => props.info, () => props.modelValue],
  async ([infoVal, modelValueVal]) => {
    if (JSON.stringify(infoVal) !== '{}') getDetail()
  }
)

const gnameChange = e => {
  const py = pinyin(e, { pattern: 'first', toneType: 'none' })
  form.value.zjm = py.replace(/\s/g, '')
}

const formRules = rules
</script>

<style lang="scss" scoped>
.news-create-container {
  display: flex;
  ::v-deep .el-form {
    flex: 1;
  }
}
.photo-container {
  width: 255px;
  margin-left: 10px;
}
</style>
